/*!
 * Bootstrap v3.3.4 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.btn-danger, .btn-default, .btn-info, .btn-primary, .btn-success, .btn-warning {
  text-shadow: 0 -1px #0003;
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-danger.active, .btn-danger:active, .btn-default.active, .btn-default:active, .btn-info.active, .btn-info:active, .btn-primary.active, .btn-primary:active, .btn-success.active, .btn-success:active, .btn-warning.active, .btn-warning:active {
  box-shadow: inset 0 3px 5px #00000020;
}

.btn-danger .badge, .btn-default .badge, .btn-info .badge, .btn-primary .badge, .btn-success .badge, .btn-warning .badge {
  text-shadow: none;
}

.btn.active, .btn:active {
  background-image: none;
}

.btn-default {
  text-shadow: 0 1px #fff;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -o-linear-gradient(top, #fff 0, #e0e0e0 100%);
  background-image: linear-gradient(#fff 0, #e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #ccc;
}

.btn-default:focus, .btn-default:hover {
  background-color: #e0e0e0;
  background-position: 0 -15px;
}

.btn-default.active, .btn-default:active {
  background-color: #e0e0e0;
  border-color: #dbdbdb;
}

.btn-default.disabled, .btn-default:disabled, .btn-default[disabled] {
  background-color: #e0e0e0;
  background-image: none;
}

.btn-primary {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -o-linear-gradient(top, #337ab7 0, #265a88 100%);
  background-image: linear-gradient(#337ab7 0, #265a88 100%);
  background-repeat: repeat-x;
  border-color: #245580;
}

.btn-primary:focus, .btn-primary:hover {
  background-color: #265a88;
  background-position: 0 -15px;
}

.btn-primary.active, .btn-primary:active {
  background-color: #265a88;
  border-color: #245580;
}

.btn-primary.disabled, .btn-primary:disabled, .btn-primary[disabled] {
  background-color: #265a88;
  background-image: none;
}

.btn-success {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -o-linear-gradient(top, #5cb85c 0, #419641 100%);
  background-image: linear-gradient(#5cb85c 0, #419641 100%);
  background-repeat: repeat-x;
  border-color: #3e8f3e;
}

.btn-success:focus, .btn-success:hover {
  background-color: #419641;
  background-position: 0 -15px;
}

.btn-success.active, .btn-success:active {
  background-color: #419641;
  border-color: #3e8f3e;
}

.btn-success.disabled, .btn-success:disabled, .btn-success[disabled] {
  background-color: #419641;
  background-image: none;
}

.btn-info {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -o-linear-gradient(top, #5bc0de 0, #2aabd2 100%);
  background-image: linear-gradient(#5bc0de 0, #2aabd2 100%);
  background-repeat: repeat-x;
  border-color: #28a4c9;
}

.btn-info:focus, .btn-info:hover {
  background-color: #2aabd2;
  background-position: 0 -15px;
}

.btn-info.active, .btn-info:active {
  background-color: #2aabd2;
  border-color: #28a4c9;
}

.btn-info.disabled, .btn-info:disabled, .btn-info[disabled] {
  background-color: #2aabd2;
  background-image: none;
}

.btn-warning {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -o-linear-gradient(top, #f0ad4e 0, #eb9316 100%);
  background-image: linear-gradient(#f0ad4e 0, #eb9316 100%);
  background-repeat: repeat-x;
  border-color: #e38d13;
}

.btn-warning:focus, .btn-warning:hover {
  background-color: #eb9316;
  background-position: 0 -15px;
}

.btn-warning.active, .btn-warning:active {
  background-color: #eb9316;
  border-color: #e38d13;
}

.btn-warning.disabled, .btn-warning:disabled, .btn-warning[disabled] {
  background-color: #eb9316;
  background-image: none;
}

.btn-danger {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -o-linear-gradient(top, #d9534f 0, #c12e2a 100%);
  background-image: linear-gradient(#d9534f 0, #c12e2a 100%);
  background-repeat: repeat-x;
  border-color: #b92c28;
}

.btn-danger:focus, .btn-danger:hover {
  background-color: #c12e2a;
  background-position: 0 -15px;
}

.btn-danger.active, .btn-danger:active {
  background-color: #c12e2a;
  border-color: #b92c28;
}

.btn-danger.disabled, .btn-danger:disabled, .btn-danger[disabled] {
  background-color: #c12e2a;
  background-image: none;
}

.img-thumbnail, .thumbnail {
  box-shadow: 0 1px 2px #00000013;
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fff5f5f5", endColorstr= "#ffe8e8e8", GradientType= 0);
  background-image: -o-linear-gradient(top, #f5f5f5 0, #e8e8e8 100%);
  background-color: #e8e8e8;
  background-image: linear-gradient(#f5f5f5 0, #e8e8e8 100%);
  background-repeat: repeat-x;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff337ab7", endColorstr= "#ff2e6da4", GradientType= 0);
  background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
  background-color: #2e6da4;
  background-image: linear-gradient(#337ab7 0, #2e6da4 100%);
  background-repeat: repeat-x;
}

.navbar-default {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -o-linear-gradient(top, #fff 0, #f8f8f8 100%);
  background-image: linear-gradient(#fff 0, #f8f8f8 100%);
  background-repeat: repeat-x;
  border-radius: 4px;
  box-shadow: inset 0 1px #ffffff26, 0 1px 5px #00000013;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .open > a {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffdbdbdb", endColorstr= "#ffe2e2e2", GradientType= 0);
  background-image: -o-linear-gradient(top, #dbdbdb 0, #e2e2e2 100%);
  background-image: linear-gradient(#dbdbdb 0, #e2e2e2 100%);
  background-repeat: repeat-x;
  box-shadow: inset 0 3px 9px #00000013;
}

.navbar-brand, .navbar-nav > li > a {
  text-shadow: 0 1px #ffffff40;
}

.navbar-inverse {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -o-linear-gradient(top, #3c3c3c 0, #222 100%);
  background-image: linear-gradient(#3c3c3c 0, #222 100%);
  background-repeat: repeat-x;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .open > a {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff080808", endColorstr= "#ff0f0f0f", GradientType= 0);
  background-image: -o-linear-gradient(top, #080808 0, #0f0f0f 100%);
  background-image: linear-gradient(#080808 0, #0f0f0f 100%);
  background-repeat: repeat-x;
  box-shadow: inset 0 3px 9px #00000040;
}

.navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px #00000040;
}

.navbar-fixed-bottom, .navbar-fixed-top, .navbar-static-top {
  border-radius: 0;
}

@media (width <= 767px) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a, .navbar .navbar-nav .open .dropdown-menu > .active > a:focus, .navbar .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #fff;
    filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff337ab7", endColorstr= "#ff2e6da4", GradientType= 0);
    background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
    background-image: linear-gradient(#337ab7 0, #2e6da4 100%);
    background-repeat: repeat-x;
  }
}

.alert {
  text-shadow: 0 1px #fff3;
  box-shadow: inset 0 1px #ffffff40, 0 1px 2px #0000000d;
}

.alert-success {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffdff0d8", endColorstr= "#ffc8e5bc", GradientType= 0);
  background-image: -o-linear-gradient(top, #dff0d8 0, #c8e5bc 100%);
  background-image: linear-gradient(#dff0d8 0, #c8e5bc 100%);
  background-repeat: repeat-x;
  border-color: #b2dba1;
}

.alert-info {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffd9edf7", endColorstr= "#ffb9def0", GradientType= 0);
  background-image: -o-linear-gradient(top, #d9edf7 0, #b9def0 100%);
  background-image: linear-gradient(#d9edf7 0, #b9def0 100%);
  background-repeat: repeat-x;
  border-color: #9acfea;
}

.alert-warning {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fffcf8e3", endColorstr= "#fff8efc0", GradientType= 0);
  background-image: -o-linear-gradient(top, #fcf8e3 0, #f8efc0 100%);
  background-image: linear-gradient(#fcf8e3 0, #f8efc0 100%);
  background-repeat: repeat-x;
  border-color: #f5e79e;
}

.alert-danger {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fff2dede", endColorstr= "#ffe7c3c3", GradientType= 0);
  background-image: -o-linear-gradient(top, #f2dede 0, #e7c3c3 100%);
  background-image: linear-gradient(#f2dede 0, #e7c3c3 100%);
  background-repeat: repeat-x;
  border-color: #dca7a7;
}

.progress {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffebebeb", endColorstr= "#fff5f5f5", GradientType= 0);
  background-image: -o-linear-gradient(top, #ebebeb 0, #f5f5f5 100%);
  background-image: linear-gradient(#ebebeb 0, #f5f5f5 100%);
  background-repeat: repeat-x;
}

.progress-bar {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff337ab7", endColorstr= "#ff286090", GradientType= 0);
  background-image: -o-linear-gradient(top, #337ab7 0, #286090 100%);
  background-image: linear-gradient(#337ab7 0, #286090 100%);
  background-repeat: repeat-x;
}

.progress-bar-success {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff5cb85c", endColorstr= "#ff449d44", GradientType= 0);
  background-image: -o-linear-gradient(top, #5cb85c 0, #449d44 100%);
  background-image: linear-gradient(#5cb85c 0, #449d44 100%);
  background-repeat: repeat-x;
}

.progress-bar-info {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff5bc0de", endColorstr= "#ff31b0d5", GradientType= 0);
  background-image: -o-linear-gradient(top, #5bc0de 0, #31b0d5 100%);
  background-image: linear-gradient(#5bc0de 0, #31b0d5 100%);
  background-repeat: repeat-x;
}

.progress-bar-warning {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fff0ad4e", endColorstr= "#ffec971f", GradientType= 0);
  background-image: -o-linear-gradient(top, #f0ad4e 0, #ec971f 100%);
  background-image: linear-gradient(#f0ad4e 0, #ec971f 100%);
  background-repeat: repeat-x;
}

.progress-bar-danger {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffd9534f", endColorstr= "#ffc9302c", GradientType= 0);
  background-image: -o-linear-gradient(top, #d9534f 0, #c9302c 100%);
  background-image: linear-gradient(#d9534f 0, #c9302c 100%);
  background-repeat: repeat-x;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.list-group {
  border-radius: 4px;
  box-shadow: 0 1px 2px #00000013;
}

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  text-shadow: 0 -1px #286090;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff337ab7", endColorstr= "#ff2b669a", GradientType= 0);
  background-image: -o-linear-gradient(top, #337ab7 0, #2b669a 100%);
  background-image: linear-gradient(#337ab7 0, #2b669a 100%);
  background-repeat: repeat-x;
  border-color: #2b669a;
}

.list-group-item.active .badge, .list-group-item.active:focus .badge, .list-group-item.active:hover .badge {
  text-shadow: none;
}

.panel {
  box-shadow: 0 1px 2px #0000000d;
}

.panel-default > .panel-heading {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fff5f5f5", endColorstr= "#ffe8e8e8", GradientType= 0);
  background-image: -o-linear-gradient(top, #f5f5f5 0, #e8e8e8 100%);
  background-image: linear-gradient(#f5f5f5 0, #e8e8e8 100%);
  background-repeat: repeat-x;
}

.panel-primary > .panel-heading {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff337ab7", endColorstr= "#ff2e6da4", GradientType= 0);
  background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
  background-image: linear-gradient(#337ab7 0, #2e6da4 100%);
  background-repeat: repeat-x;
}

.panel-success > .panel-heading {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffdff0d8", endColorstr= "#ffd0e9c6", GradientType= 0);
  background-image: -o-linear-gradient(top, #dff0d8 0, #d0e9c6 100%);
  background-image: linear-gradient(#dff0d8 0, #d0e9c6 100%);
  background-repeat: repeat-x;
}

.panel-info > .panel-heading {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffd9edf7", endColorstr= "#ffc4e3f3", GradientType= 0);
  background-image: -o-linear-gradient(top, #d9edf7 0, #c4e3f3 100%);
  background-image: linear-gradient(#d9edf7 0, #c4e3f3 100%);
  background-repeat: repeat-x;
}

.panel-warning > .panel-heading {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fffcf8e3", endColorstr= "#fffaf2cc", GradientType= 0);
  background-image: -o-linear-gradient(top, #fcf8e3 0, #faf2cc 100%);
  background-image: linear-gradient(#fcf8e3 0, #faf2cc 100%);
  background-repeat: repeat-x;
}

.panel-danger > .panel-heading {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fff2dede", endColorstr= "#ffebcccc", GradientType= 0);
  background-image: -o-linear-gradient(top, #f2dede 0, #ebcccc 100%);
  background-image: linear-gradient(#f2dede 0, #ebcccc 100%);
  background-repeat: repeat-x;
}

.well {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffe8e8e8", endColorstr= "#fff5f5f5", GradientType= 0);
  background-image: -o-linear-gradient(top, #e8e8e8 0, #f5f5f5 100%);
  background-image: linear-gradient(#e8e8e8 0, #f5f5f5 100%);
  background-repeat: repeat-x;
  border-color: #dcdcdc;
  box-shadow: inset 0 1px 3px #0000000d, 0 1px #ffffff1a;
}
/*# sourceMappingURL=index.34379f7e.css.map */
